<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    :disabled="disabled"
    transition="scale-transition"
    offset-y
    min-width="auto"
  >
    <template #activator="{ on, attrs }">
      <v-text-field
        :value="formattedDate"
        :label="label"
        :placeholder="placeholder"
        :error-messages="defaultedMessagesToDisplay"
        :disabled="disabled"
        :hide-details="dense"
        :prepend-icon="$icons.mdiCalendar"
        readonly
        clearable
        v-bind="attrs"
        v-on="on"
        @click:clear="handleInput('')"
      ></v-text-field>
    </template>

    <v-date-picker
      v-model="date"
      :locale="locale"
      :max="max"
      :min="min"
      :show-current="false"
      @input="handleInput"
    ></v-date-picker>
  </v-menu>
</template>

<script>
import { VInput } from 'vuetify/lib'

export default {
  name: 'DateType',
  extends: VInput,
  props: {
    value: {
      type: String,
      required: true
    },
    label: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    locale: {
      type: String,
      default: 'de'
    },
    rules: {
      type: Array,
      default: () => []
    },
    min: {
      type: String,
      default: undefined
    },
    max: {
      type: String,
      default: undefined
    },
    disabled: {
      type: Boolean,
      default: false
    },
    dense: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      menu: false,
      date: this.value
    }
  },
  methods: {
    handleInput (data) {
      this.$emit('input', data)
      this.menu = false
    }
  },
  computed: {
    formattedDate () {
      const options = { year: 'numeric', month: '2-digit', day: '2-digit' }
      return this.value ? new Date(this.value).toLocaleDateString(this.locale, options) : ''
    },
    defaultedMessagesToDisplay () {
      return this.messagesToDisplay ?? []
    }
  }
}
</script>

<style scoped>
/*noinspection CssInvalidPseudoSelector*/
:deep(.v-label--active) {
  pointer-events: none;
}
</style>
