import mapValues from 'lodash/mapValues'

export const hexToRgb = (hex) => {
  const matches = hex.match(/^#?([A-Fa-f0-9]{2})([A-Fa-f0-9]{2})([A-Fa-f0-9]{2})$/i)
  matches.shift()

  const [r, g, b] = matches.map(hex => parseInt(hex, 16))

  return { r, g, b }
}

export const luminance = (rgb) => {
  const rgbs = mapValues(rgb, colorValue => colorValue / 255)

  const { r, g, b } = mapValues(rgbs, colorValue => colorValue <= 0.03928 ? (colorValue / 12.92) : ((colorValue + 0.055) / 1.055) ** 2.4)

  return r * 0.2126 + g * 0.7152 + b * 0.0722
}

export const contrast = (color1, color2) => {
  const l1 = luminance(hexToRgb(color1))
  const l2 = luminance(hexToRgb(color2))

  const ratio = (l1 + 0.05) / (l2 + 0.05)

  return Math.round((l2 > l1 ? 1 / ratio : ratio) * 100) / 100
}
