<template>
  <v-hover v-slot="{ hover }">
    <label for="fileInput" :class="{'cursor-pointer': !readonly}">
      <input
        class="d-none"
        type="file"
        name="logo"
        id="fileInput"
        ref="fileInput"
        :accept="acceptString"
        @change="handleInputChange"
        :disabled="$props.readonly"
      >

      <v-btn
        :disabled="loading"
        :loading="loading"
        class="hidden-md-and-up my-4"
        color="primary"
        tag="span"
      >
        <v-icon class="mr-2">{{ $icons.mdiUpload }}</v-icon>
        {{ $t('global.components.fileUploader.buttonText') }}
      </v-btn>

      <v-sheet
        :color="getColor(hover)"
        :elevation="elevation"
        class="transition-fast-in-fast-out px-16 py-12 justify-center align-center d-md-flex hidden-sm-and-down"
        height="150"
        @dragenter.prevent="setActive(true)"
        @dragleave.prevent="setActive(false)"
        @dragover.prevent="handleDragOver"
        @drop.prevent="handleDrop"
      >
        <v-progress-circular
          v-if="loading"
          :size="32"
          color="primary"
          width="2"
          indeterminate
        ></v-progress-circular>

        <slot v-else>
          <div v-html="$t('global.components.fileUploader.text')"></div>
        </slot>
      </v-sheet>
    </label>
  </v-hover>
</template>

<script>
import { mapActions } from 'vuex'
import { addFile } from '@/api/files.api'

export default {
  name: 'FileUploader',
  props: {
    accept: {
      type: Array,
      default: () => []
    },
    readonly: {
      type: Boolean,
      default: false
    },
    maxSize: {
      type: Number,
      default: -1
    }
  },
  data: () => ({
    active: false,
    loading: false
  }),
  computed: {
    color () {
      return this.active ? 'grey lighten-3' : 'grey lighten-5'
    },
    elevation () {
      return this.active ? '3' : '1'
    },
    acceptString () {
      return this.accept.join(', ')
    }
  },
  methods: {
    ...mapActions('messageQueue', ['queueError']),
    async handleUpload (file) {
      if (this.accept.length > 0 && !this.accept.includes(file.type)) {
        this.queueError(this.$t('global.components.fileUploader.errors.format'))
        return
      }

      if (this.maxSize > -1 && file.size > this.maxSize) {
        const kB = Math.round(this.maxSize / 1024)

        this.queueError(this.$t('global.components.fileUploader.errors.size', { size: kB }))
        return
      }

      try {
        this.loading = true

        const { data: id } = await addFile(file)
        this.$emit('uploaded', { id })
      } catch (e) {
        this.queueError(this.$t('global.components.fileUploader.errors.generic'))
        this.$cError(e.message)
      } finally {
        this.loading = false
      }
    },
    handleInputChange () {
      this.handleUpload(this.$refs.fileInput.files[0])
    },
    handleDragOver (event) {
      event.preventDefault()
    },
    handleDrop (event) {
      if (!this.readonly) {
        if (!event.dataTransfer.files || event.dataTransfer.files.length !== 1) {
          return
        }

        this.handleUpload(event.dataTransfer.files[0])

        this.active = false
      }
    },
    getColor (hover) {
      return hover && !this.readonly ? 'grey lighten-3' : this.color
    },
    setActive (active) {
      if (!this.readonly) {
        this.active = active
      }
    }
  }
}
</script>

<style scoped>
.cursor-pointer {
  cursor: pointer;
}
</style>
