<template>
  <span>{{ formattedDate }} <span v-if="time" v-text="timeString"></span></span>
</template>

<script>
import { toLocaleDateString } from '@/helpers/datetime.helper'

export default {
  name: 'FormattedDate',
  props: {
    date: {
      type: String,
      required: true
    },
    locale: {
      type: String,
      default: null
    },
    options: {
      type: Object,
      default: null
    },
    time: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    formattedDate () {
      return toLocaleDateString(this.date, this.dateStringOptions, this.locale)
    },
    dateStringOptions () {
      if (this.options) {
        return this.options
      }

      if (this.time) {
        return { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' }
      }

      return { year: 'numeric', month: '2-digit', day: '2-digit' }
    },
    timeString () {
      return this.$t('global.units.timeString')
    }
  }
}
</script>
