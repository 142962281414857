<template>
  <div>
    <div v-if="!initialLoad" class="spinner-wrapper">
      <v-progress-circular
        :size="64"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </div>

    <div v-show="initialLoad">
      <contract-summary-settings
        form-name="contractSummarySettingsForm"
        @reset="handleReset"
      ></contract-summary-settings>

      <font-settings
        form-name="fontSettingsForm"
        @changed="handleFormChanged"
      ></font-settings>

      <period-settings
        form-name="periodSettingsForm"
        @changed="handleFormChanged"
      ></period-settings>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import ContractSummarySettings from './ContractSummarySettings'
import FontSettings from './FontSettings'
import PeriodSettings from './PeriodSettings'

export default {
  name: 'SettingsForm',
  components: {
    FontSettings,
    ContractSummarySettings,
    PeriodSettings
  },
  data () {
    return {
      dirtyForms: {
        contractSummarySettingsForm: false,
        fontSettingsForm: false,
        periodSettingsForm: false
      }
    }
  },
  created () {
    if (!this.initialLoad) {
      this.fetchSettings()
    }
  },
  methods: {
    ...mapActions('settings', ['fetchSettings']),
    ...mapActions('messageQueue', ['queueError', 'queueSuccess']),
    successHandler (formName) {
      return () => {
        setTimeout(() => {
          this.dirtyForms[formName] = false
        }, 250)
        this.queueSuccess(this.$tp('components.success'))
      }
    },
    errorHandler () {
      this.queueError(this.$tp('components.error'))
    },
    handleFormChanged (formName) {
      this.dirtyForms[formName] = true
    },
    handleReset () {
      this.handleFormChanged('contractSummarySettingsForm')
    }
  },
  computed: {
    ...mapGetters('settings', ['loading', 'initialLoad']),
    hasDirtyForms () {
      return Object.keys(this.dirtyForms)
        .reduce((acc, cur) => {
          return acc || this.dirtyForms[cur]
        }, false)
    }
  },
  inject: [
    '$tp'
  ],
  provide () {
    return {
      successHandler: this.successHandler,
      errorHandler: this.errorHandler
    }
  },
  watch: {
    hasDirtyForms (newValue) {
      this.$emit('dirty', newValue)
    }
  }
}
</script>

<style scoped>
.spinner-wrapper {
  min-height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
