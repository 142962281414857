export default {
  data () {
    return {
      leaveRouteConfirmationDialog: false,
      leaveTo: null,
      watchRouteChange: true
    }
  },
  beforeRouteLeave (to, from, next) {
    if (this.watchRouteChange && this.$refs.leaveRouteConfirmationDialog !== undefined && this.leaveTo === null) {
      this.leaveTo = to
      this.leaveRouteConfirmationDialog = true
    } else {
      next()
    }
  },
  methods: {
    cancelLeaveRoute () {
      this.leaveRouteConfirmationDialog = false
    },
    confirmLeaveRoute () {
      this.$router.push(this.leaveTo)
    }
  },
  computed: {
    leaveRouteConfirmationDialogTitle () {
      return this.$tp('components.dialogs.leaveRouteConfirmation.title')
    },
    leaveRouteConfirmationDialogText () {
      return this.$tp('components.dialogs.leaveRouteConfirmation.text')
    },
    leaveRouteConfirmationDialogCancelText () {
      return this.$tp('components.dialogs.leaveRouteConfirmation.buttons.cancel')
    },
    leaveRouteConfirmationDialogConfirmText () {
      return this.$tp('components.dialogs.leaveRouteConfirmation.buttons.confirm')
    }
  },
  watch: {
    leaveRouteConfirmationDialog (newValue) {
      if (!newValue) {
        this.leaveTo = null
      }
    }
  }
}
