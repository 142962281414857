<template>
  <v-radio-group
    :value="selection"
    :label="label"
    :rules="rules"
    :disabled="disabled"
    @change="handleChange"
  >
    <v-radio
      v-for="({ label, value }) in options"
      :key="value"
      :label="label"
      :value="value"
      :name="name"
      :v-model="name"
    ></v-radio>
  </v-radio-group>
</template>

<script>
export default {
  name: 'RadioGroupType',
  props: {
    value: {
      required: true
    },
    label: {
      type: String,
      default: ''
    },
    options: {
      type: Array,
      required: true
    },
    rules: {
      type: Array,
      default: () => []
    },
    required: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    name: {
      type: String,
      default: null
    }
  },
  data: () => ({
    selection: ''
  }),
  created () {
    this.selection = this.value
  },
  methods: {
    handleChange (data) {
      this.$emit('input', data)
    }
  },
  computed: {
    valid () {
      return !this.required || (this.selection !== null && this.selection !== undefined)
    }
  }
}
</script>
