<template>
  <v-data-table
    v-bind="dataTableProps"
    v-on="eventHandlers"
    v-model="selected"
  >
    <template #top v-if="showTop">
      <v-card flat>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="6" order="last" order-sm="first">
                <v-menu offset-y v-if="bulkActions.length > 0">
                  <template #activator="{ on, attrs }">
                    <v-btn
                      color="grey"
                      dark
                      v-bind="attrs"
                      v-on="on"
                    >
                      Bulk Actions
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item
                      v-for="({ title, handler }, i) in bulkActions"
                      :key="i"
                      link
                      @click="handler(selected)"
                    >
                      <v-list-item-title>{{ title }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-col>

              <v-col cols="12" sm="6" v-if="searchAction">
                <v-text-field
                  v-model="search"
                  :append-icon="$icons.mdiMagnify"
                  :label="searchAction.label"
                  :hint="searchAction.hint"
                  :rules="searchRules"
                  outlined
                  persistent-hint
                  clearable
                  @keydown.enter="handleSearch(search)"
                  @click:append="handleSearch(search)"
                  @click:clear="handleSearch('')"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </template>

    <template #item.actions="{ item }" v-if="actions.length > 0">
      <template v-for="({ icon, handler, disabled, tooltip }, i) in actions">
        <template
          v-if="tooltip"
        >
          <v-tooltip
            left
            :key="i"
            :open-delay="250"
          >
            <template #activator="{ on, attrs }">
              <v-icon
                small
                class="mr-2"
                @click="handler(item)"
                :disabled="isDisabled(disabled, item)"
                v-bind="attrs"
                v-on="on"
              >
                {{ icon }}
              </v-icon>
            </template>
            <span v-html="tooltip"></span>
          </v-tooltip>
        </template>
        <template
          v-else
        >
          <v-icon
            :key="i"
            small
            class="mr-2"
            @click="handler(item)"
            :disabled="isDisabled(disabled, item)"
          >
            {{ icon }}
          </v-icon>
        </template>
      </template>
    </template>

    <template v-for="value in headerScopedSlotNames" #[value]="{ header }">
      {{ getHeaderText(header) }}
    </template>

    <template v-for="(_, name) in $scopedSlots" #[name]="data">
      <slot :name="name" v-bind="data"></slot>
    </template>
  </v-data-table>
</template>

<script>
import isFunction from 'lodash/isFunction'
import merge from 'lodash/merge'

export default {
  name: 'DataTable',
  props: {
    dataTable: {
      type: Object,
      required: true
    },
    eventHandlers: {
      type: Object,
      default: () => ({})
    },
    actions: {
      type: Array,
      default: () => ([])
    },
    bulkActions: {
      type: Array,
      default: () => ([])
    },
    searchAction: {
      type: Object,
      default: null
    },
    tableName: {
      type: String,
      default: 'table'
    },
    minSearchTermLength: {
      type: Number,
      default: -1
    }
  },
  data () {
    return {
      options: {},
      dialog: false,
      dialogDelete: false,
      activeItem: null,
      selected: [],
      search: '',
      searchRules: [
        (v) => {
          if (!v) {
            return true
          }

          if (v.length >= this.minSearchTermLength) {
            return true
          }

          return this.$t('global.components.dataTable.search.minLength', { min: this.minSearchTermLength })
        }
      ]
    }
  },
  methods: {
    getHeaderText (header) {
      if (header.text) {
        return header.text
      }

      if (header.value && header.value !== 'actions') {
        return this.$td(
          `${this.tableTranslationKey}.headers.${header.value}`,
          header.value
        )
      }

      return ''
    },
    isDisabled (disabled, item) {
      if (isFunction(disabled)) {
        return disabled(item)
      }

      return !!disabled
    },
    handleSearch (searchTerm) {
      if (!this.searchAction) {
        return
      }

      if (!searchTerm) {
        this.searchAction.handler('')
      }

      if (searchTerm.length < this.minSearchTermLength) {
        return
      }

      this.searchAction.handler(searchTerm)
    }
  },
  computed: {
    showTop () {
      return this.bulkActions.length > 1 || this.searchAction !== null
    },
    dataTableProps () {
      const dataTableProps = Object.assign({}, this.dataTable)

      if (dataTableProps.headers) {
        dataTableProps.headers = dataTableProps.headers.map((header) => {
          header.text = this.getHeaderText(header)

          return header
        })
      }

      return merge({}, this.defaultProps, this.dataTable)
    },
    headerScopedSlotNames () {
      return (this.dataTable?.headers ?? [])
        .filter(({ value }) => !!value)
        .map(({ value }) => `header.${value}`)
        .filter(name => !this.$scopedSlots[name])
    },
    defaultProps () {
      const defaultProps = {
        class: 'elevation-1',
        sortBy: [],
        headerProps: {
          sortByText: this.$t('global.components.dataTable.sorting.sortBy')
        },
        footerProps: {
          itemsPerPageOptions: [5, 10, 20, 50, 100],
          showFirstLastPage: true
        },
        loadingText: this.$t('global.components.dataTable.loadingText'),
        noDataText: this.$t('global.components.dataTable.noDataText'),
        noResultsText: this.$t('global.components.dataTable.noResultsText', [this.search])
      }

      const translationKey = `${this.tableTranslationKey}.footer.itemsPerPage`

      if (this.$te(translationKey)) {
        defaultProps.footerProps.itemsPerPageText = this.$t(translationKey)
      }

      return defaultProps
    },
    tableTranslationKey () {
      return `${this.pageTranslationKey}.components.${this.tableName}`
    }
  },
  inject: [
    '$td',
    'pageTranslationKey'
  ]
}
</script>

<style scoped>
/*noinspection CssInvalidPseudoSelector*/
:deep(.v-data-table-header-mobile__wrapper .v-select__slot) {
  margin: 0.5rem 0;
}

/*noinspection CssInvalidPseudoSelector*/
:deep(.v-data-table-header-mobile__wrapper .v-text-field .v-label--active) {
  transform: translateY(-24px) scale(0.75);
}

/*noinspection CssInvalidPseudoSelector*/
.v-data-table :deep(.v-data-table__wrapper .v-data-table__mobile-row) {
  min-height: 36px
}
</style>
