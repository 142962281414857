<template>
  <div>
    <v-card>
      <v-card-text>
        <img
          v-if="isImage"
          :src="source"
          :style="cssVars"
          :alt="slotContent"
          class="embedded-object mx-auto d-block"
          loading="lazy"
        >

        <iframe
          v-else
          v-bind="{ width, height }"
          :src="source"
          :style="cssVars"
          class="embedded-object"
          loading="lazy"
        >
          <slot></slot>
        </iframe>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-dialog v-model="isFullscreen" fullscreen hide-overlay>
          <v-card class="preview-card">
            <div class="preview-card__inner">
              <v-toolbar color="primary" dark class="preview-card__header">
                <v-tooltip right>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-on="on"
                      v-bind="attrs"
                      icon
                      dark
                      @click="exitFullscreen"
                    >
                      <v-icon>{{ $icons.mdiClose }}</v-icon>
                    </v-btn>
                  </template>
                  <span v-text="closeButtonTooltip"></span>
                </v-tooltip>

                <v-toolbar-title>{{ heading }}</v-toolbar-title>

                <v-spacer></v-spacer>

                <v-tooltip left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      v-if="showDownload"
                      :href="downloadLink"
                      icon
                      dark
                      target="_blank"
                      download
                    >
                      <v-icon>{{ $icons.mdiDownload }}</v-icon>
                    </v-btn>
                  </template>
                  <span v-text="downloadButtonTooltip"></span>
                </v-tooltip>
              </v-toolbar>

              <div class="embedded-object embedded-object--fullscreen" :style="cssVarsFullscreen">
                <img
                  v-if="isImage"
                  :src="source"
                  :style="cssVars"
                  :alt="slotContent"
                  class="embedded-object embedded-object--fullscreen flex-grow-1"
                  loading="lazy"
                >

                <iframe
                  v-else
                  :src="source"
                  class="embedded-object embedded-object--fullscreen flex-grow-1"
                >
                  <slot></slot>
                </iframe>
              </div>
            </div>
          </v-card>
        </v-dialog>

        <v-tooltip left>
          <template #activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on" @click="isFullscreen = true">
              <v-icon>{{ $icons.mdiFullscreen }}</v-icon>
            </v-btn>
          </template>
          <span v-text="previewButtonTooltip"></span>
        </v-tooltip>

        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-on="on" v-bind="attrs" icon :href="downloadLink" target="_blank" download v-if="showDownload">
              <v-icon>{{ $icons.mdiDownload }}</v-icon>
            </v-btn>
          </template>
          <span v-text="downloadButtonTooltip"></span>
        </v-tooltip>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'EmbeddedObject',
  props: {
    source: {
      type: String,
      required: true
    },
    type: {
      type: String,
      default: ''
    },
    width: {
      type: [Number, String],
      default: null
    },
    height: {
      type: [Number, String],
      default: null
    },
    fullscreenWidth: {
      type: [Number, String],
      default: null
    },
    fullscreenHeight: {
      type: [Number, String],
      default: null
    },
    download: {
      type: [Boolean, String],
      default: true
    },
    fullscreen: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      isFullscreen: false
    }
  },
  methods: {
    exitFullscreen () {
      this.isFullscreen = false
    }
  },
  computed: {
    showDownload () {
      return !!this.download
    },
    downloadLink () {
      if (!this.download) {
        return null
      }

      return this.download === true ? this.source : this.download
    },
    cssVars () {
      const vars = {}

      if (this.height) {
        vars['--embedded-object-height'] = isNaN(this.height) ? this.height : `${this.height}px`
      }

      if (this.width) {
        vars['--embedded-object-width'] = isNaN(this.width) ? this.width : `${this.width}px`
      }

      return vars
    },
    cssVarsFullscreen () {
      const vars = {}

      if (this.fullscreenHeight) {
        vars['--embedded-object-height'] = isNaN(this.fullscreenHeight) ? this.fullscreenHeight : `${this.fullscreenHeight}px`
      }

      if (this.fullscreenWidth) {
        vars['--embedded-object-width'] = isNaN(this.fullscreenWidth) ? this.fullscreenWidth : `${this.fullscreenWidth}px`
      }

      return vars
    },
    heading () {
      return this.$t('global.components.embeddedObject.heading')
    },
    isImage () {
      return /^image.*/.test(this.type)
    },
    slotContent () {
      const defaultSlot = this.$slots.default

      if (!defaultSlot) {
        return ''
      }

      const firstDefaultSlot = defaultSlot[0]

      if (!firstDefaultSlot) {
        return ''
      }

      return firstDefaultSlot.text?.trim() ?? ''
    },
    downloadButtonTooltip () {
      return this.$t('global.components.embeddedObject.download')
    },
    previewButtonTooltip () {
      return this.$t('global.components.embeddedObject.preview')
    },
    closeButtonTooltip () {
      return this.$t('global.components.embeddedObject.close')
    }
  }
}
</script>

<style scoped>
.preview-card {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.preview-card__inner {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.preview-card__header {
  flex-grow: 0;
}

.embedded-object {
  --embedded-object-width: auto;
  --embedded-object-height: 300px;

  width: var(--embedded-object-width);
  height: var(--embedded-object-height);

  border: none;

  max-width: 100%;
}

.embedded-object--fullscreen {
  --embedded-object-width: 100%;
  --embedded-object-height: 100%;

  max-width: 100%;
  padding: 40px;
}
</style>
