<template>
  <div>
    <div
      v-if="!$props.disabled"
      class="mb-4"
    >
      <file-uploader
        :accept="accept"
        :disabled="$props.disabled"
        :max-size="maxSize"
        @uploaded="handleUploaded"
      >
        <slot :name="`fileType.${name}`" :uuid="value" :field="name" :accept="accept">
          <div class="text-center">
            <div v-html="text"></div>
            <div
              v-if="hintText.length > 0"
              class="text-body-2 grey--text text--darken-2 mt-2"
            >
              {{ hintText }}
            </div>
          </div>
        </slot>
      </file-uploader>
    </div>

    <slot :name="`fileType.${name}.after`" :uuid="value"></slot>

    <div v-if="hasError" class="mb-2">
      <span class="red--text" v-for="(message, i) in messagesToDisplay" :key="i">{{ message }}</span>
    </div>
  </div>
</template>

<script>
import { VInput } from 'vuetify/lib'

import FileUploader from '../FileUploader'

import i18n from '@/mixins/i18n.mixin'

export default {
  name: 'FileType',
  components: { FileUploader },
  mixins: [
    i18n
  ],
  extends: VInput,
  props: {
    accept: {
      type: Array,
      default: () => []
    },
    name: {
      type: String,
      required: true
    },
    value: {
      type: String,
      default: ''
    },
    maxSize: {
      type: Number,
      default: -1
    }
  },
  inject: ['baseFieldTranslationKey'],
  methods: {
    handleUploaded ({ id }) {
      if (!id || id === this.value) {
        return
      }

      this.$emit('input', id)
    }
  },
  computed: {
    baseTranslationKey () {
      return `${this.baseFieldTranslationKey}.${this.name}`
    },
    text () {
      const defaultText = this.$t('global.components.fileUploader.text')

      return this.$td(`${this.baseTranslationKey}.text`, defaultText)
    },
    hintText () {
      return this.$td(`${this.baseTranslationKey}.hint`, '')
    }
  }
}
</script>
