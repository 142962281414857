<template>
  <v-container fluid class="mx-0 mb-0 mt-12 pa-0">
    <help-dialog
      :path="headingPath"
      tag="h2"
      css-classes="text-h6 text-md-h5 font-weight-bold mb-1 mb-sm-2"
    ></help-dialog>

    <p class="text-body-2 text-sm-body-1 mb-6">{{ subHeading }}</p>

    <v-row>
      <v-col cols="12" sm="auto">
        <span class="text-body-1 text-md-h6 font-weight-bold mb-2 d-inline-block">{{ fontSizeHeading }}</span>
        <v-select
          v-model="currentFontSize"
          :items="fontSizes"
          :label="fontSizeLabel"
          :placeholder="fontSizePlaceholder"
          hide-details="auto"
          outlined
          :disabled="disabled"
          @change="handleChange"
        ></v-select>
      </v-col>
      <v-col cols="12" sm="auto">
        <span class="text-body-1 text-md-h6 font-weight-bold mb-2 d-inline-block">{{ fontHeading }}</span>
        <v-select
          v-model="currentFont"
          :items="getFonts()"
          :label="fontLabel"
          :placeholder="fontPlaceholder"
          hide-details="auto"
          outlined
          :disabled="disabled"
          @change="handleChange"
        ></v-select>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" sm="auto">
        <span class="text-body-1 text-md-h6 font-weight-bold mb-2 d-inline-block">{{ primaryColorHeading }}</span>
        <v-color-picker mode="hexa" v-model="primaryColor" :disabled="disabled" @update:color="handleChange"></v-color-picker>
      </v-col>

      <v-col cols="12" sm="auto">
        <span class="text-body-1 text-md-h6 font-weight-bold mb-2 d-inline-block">{{ secondaryColorHeading }}</span>
        <v-color-picker mode="hexa" v-model="secondaryColor" :disabled="disabled" @update:color="handleChange"></v-color-picker>
      </v-col>

      <v-col cols="auto" md="6" lg="5">
        <span class="text-body-1 text-md-h6 font-weight-bold mb-2 d-inline-block">{{ previewHeading }}</span>

        <div :style="colorPreviewBgStyles" class="pa-8 rounded elevation-2 preview">
          <div
            :style="colorPreviewTextStyles"
            class="preview__text preview__text--lg"
            v-html="previewTextLg"
          ></div>

          <div
            :style="colorPreviewTextStyles"
            class="preview__text preview__text--md"
            v-html="previewTextMd"
          ></div>

          <div
            :style="colorPreviewTextStyles"
            class="preview__text preview__text--sm"
            v-html="previewTextSm"
          ></div>
        </div>

        <div class="my-4 text-h6 d-flex" :class="colorContrastColorClass">
          <help-dialog
            :path="previewContrast"
            tag="span"
          ></help-dialog>: {{ colorContrastForDisplay }} ({{ colorContrastRating }})
        </div>
      </v-col>
    </v-row>

    <v-btn color="primary" @click="handleSubmit" :disabled="disabled">{{ saveButton }}</v-btn>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { contrast } from '@/helpers/colors.helper'

import HelpDialog from '../HelpDialog'

import UserRoleAwareMixin from '@/mixins/userRoleAware.mixin'
import config from '@/config/config'

export default {
  name: 'FontSettings',
  mixins: [
    UserRoleAwareMixin
  ],
  components: {
    HelpDialog
  },
  props: {
    formName: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      primaryColor: '',
      secondaryColor: '',
      formTranslationKey: 'components.stylesForm',
      fontSizes: [{ text: '12 pt', value: 12 }, { text: '11 pt', value: 11 }, { text: '10 pt', value: 10 }],
      currentFontSize: '',
      currentFont: ''
    }
  },
  methods: {
    ...mapActions('settings', ['updateSettings']),
    setData () {
      this.currentFontSize = this.fontSize
      this.currentFont = this.font
      this.primaryColor = this.colors.primaryColor
      this.secondaryColor = this.colors.secondaryColor
    },
    handleSubmit () {
      this.updateSettings({
        data: {
          font: this.currentFont,
          fontSize: this.currentFontSize,
          primaryColor: this.primaryColor,
          secondaryColor: this.secondaryColor
        },
        handlers: {
          '2xx': this.successHandler(this.formName),
          '4xx': this.errorHandler
        }
      })
    },
    handleChange () {
      this.$emit('changed', this.formName)
    },
    getFonts () {
      const fonts = config.getRaw('requests.templates.fonts') || []

      return fonts.map((font) => ({ text: font, value: font }))
    }
  },
  created () {
    if (this.initialLoad) {
      this.setData()
    }
  },
  computed: {
    ...mapGetters('settings', ['settings', 'colors', 'initialLoad', 'fontSize', 'font']),
    colorPreviewTextStyles () {
      return { color: this.primaryColor }
    },
    colorPreviewBgStyles () {
      return { backgroundColor: this.secondaryColor }
    },
    colorContrast () {
      if (this.primaryColor.length !== 7 || this.secondaryColor.length !== 7) {
        return 1
      }

      return contrast(this.primaryColor, this.secondaryColor)
    },
    colorContrastForDisplay () {
      return `${this.colorContrast}:1`
    },
    headingPath () {
      return `${this.formTranslationKey}.heading`
    },
    subHeading () {
      return this.$tp(`${this.formTranslationKey}.subHeading`)
    },
    primaryColorHeading () {
      return this.$tp(`${this.formTranslationKey}.fields.primaryColor.heading`)
    },
    secondaryColorHeading () {
      return this.$tp(`${this.formTranslationKey}.fields.secondaryColor.heading`)
    },
    previewHeading () {
      return this.$tp(`${this.formTranslationKey}.preview.heading`)
    },
    previewTextLg () {
      return this.$tp(`${this.formTranslationKey}.preview.texts.lg`)
    },
    previewTextMd () {
      return this.$tp(`${this.formTranslationKey}.preview.texts.md`)
    },
    previewTextSm () {
      return this.$tp(`${this.formTranslationKey}.preview.texts.sm`)
    },
    previewContrast () {
      return `${this.formTranslationKey}.preview.contrast`
    },
    saveButton () {
      return this.$tp(`${this.formTranslationKey}.buttons.submit`)
    },
    fontSizeHeading () {
      return this.$tp(`${this.formTranslationKey}.fields.fontSize.heading`)
    },
    fontSizeLabel () {
      return this.$tp(`${this.formTranslationKey}.fields.fontSize.label`)
    },
    fontSizePlaceholder () {
      return this.$tp(`${this.formTranslationKey}.fields.fontSize.placeholder`)
    },
    fontHeading () {
      return this.$tp(`${this.formTranslationKey}.fields.font.heading`)
    },
    fontLabel () {
      return this.$tp(`${this.formTranslationKey}.fields.font.label`)
    },
    fontPlaceholder () {
      return this.$tp(`${this.formTranslationKey}.fields.font.placeholder`)
    },
    disabled () {
      return !this.isAdmin && !this.isManager
    },
    poorContrast () {
      return this.$tp(`${this.formTranslationKey}.preview.poorContrast`)
    },
    goodContrast () {
      return this.$tp(`${this.formTranslationKey}.preview.goodContrast`)
    },
    excellentContrast () {
      return this.$tp(`${this.formTranslationKey}.preview.excellentContrast`)
    },
    colorContrastRating () {
      if (this.colorContrast < 4.5) {
        return this.poorContrast
      }

      if (this.colorContrast < 7) {
        return this.goodContrast
      }

      return this.excellentContrast
    },
    colorContrastColorClass () {
      if (this.colorContrast < 4.5) {
        return 'red--text text--darken-4'
      }

      if (this.colorContrast < 7) {
        return 'grey--text text--darken-4'
      }

      return 'green--text text--darken-4'
    }
  },
  inject: [
    'errorHandler',
    'successHandler',
    '$tp'
  ],
  watch: {
    settings () {
      this.setData()
    }
  }
}
</script>

<style scoped>
.preview {
  min-height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.preview__text + .preview__text {
  margin-top: .5rem;
}

.preview__text--lg {
  font-size: 12pt;
}

.preview__text--md {
  font-size: 11pt;
}

.preview__text--sm {
  font-size: 10pt;
}
</style>
