<template>
  <div v-if="helpDialog">
    <help-dialog v-if="heading.length > 0" :path="heading" css-classes="text-h6"></help-dialog>
    <help-dialog v-if="subHeading.length > 0" :path="subHeading" css-classes="text-subtitle-1"></help-dialog>
    <help-dialog v-if="paragraph.length > 0" :path="paragraph" css-classes="text-body-2 mb-0"></help-dialog>
  </div>

  <div v-else>
    <div v-if="heading.length > 0" class="text-h6">{{ heading }}</div>
    <div v-if="subHeading.length > 0" class="text-subtitle-1">{{ subHeading }}</div>
    <p v-if="paragraph.length > 0" class="text-body-2 mb-0">{{ paragraph }}</p>
  </div>
</template>

<script>
import HelpDialog from '../HelpDialog'

export default {
  name: 'TextType',
  components: {
    HelpDialog
  },
  props: {
    heading: {
      type: String,
      default: ''
    },
    subHeading: {
      type: String,
      default: ''
    },
    paragraph: {
      type: String,
      default: ''
    },
    helpDialog: {
      type: Boolean,
      default: false
    }
  }
}
</script>
