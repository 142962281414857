<template>
  <div>
    <v-navigation-drawer
      :value="value"
      @input="close"
      mobile-breakpoint="1024"
      width="576"
      tag="div"
      touchless
      right
      app
      disable-resize-watcher
    >
      <v-btn
        plain
        fab
        absolute
        right
        :x-small="$vuetify.breakpoint.xs"
        :small="$vuetify.breakpoint.sm"
        @click="close(false)"
        class="mr-n2 mr-sm-n4 mr-md-0"
      >
        <v-icon>{{ $icons.mdiClose }}</v-icon>
      </v-btn>

      <div class="pa-8 pr-10 pa-md-16">
        <slot></slot>
      </div>
    </v-navigation-drawer>
  </div>
</template>

<script>
export default {
  name: 'SidePanel',
  props: {
    value: {
      type: Boolean,
      required: true
    }
  },
  methods: {
    close (data) {
      this.$emit('input', data)
    }
  }
}
</script>
