<template>
  <div class="mt-12">
    <help-dialog
      :path="headingPath"
      tag="h2"
      css-classes="text-h6 text-md-h5 font-weight-bold mb-1 mb-sm-2"
    ></help-dialog>

    <data-table
      table-name="mailTemplates.table"
      :event-handlers="{}"
      :data-table="dataTable"
      :actions="actions"
    >
      <template #item.name="{ item }">
        <copy :text="item.name"></copy>
      </template>

      <template #item.createdOn="{ item }">
        <formatted-date :date="item.createdOn"></formatted-date>
      </template>

      <template #item.updatedOn="{ item }">
        <formatted-date :date="item.updatedOn"></formatted-date>
      </template>

      <template #item.uuid="{ item }">
        <copy :text="item.uuid" :display="shortenUuid(item.uuid)"></copy>
      </template>
    </data-table>

    <component-guard
      :roles="['admin', 'manager']"
    >
      <v-btn
        class="mt-4"
        color="primary"
        @click="openSidePanel"
      >{{ createButton }}</v-btn>
    </component-guard>

    <v-dialog
      width="600"
      v-model="previewDialog"
    >
      <v-card
        class="px-4 py-4"
        v-if="previewTemplate"
      >
        <v-form
          v-model="previewFromValid"
        >
          <v-card-title>{{ previewDialogTitle }}</v-card-title>
          <v-card-subtitle>{{ previewDialogSubTitle }}</v-card-subtitle>

          <v-card-text>
            <v-text-field
              v-model="previewEmail"
              type="email"
              :rules="previewDialogEmailRules"
              outlined
              :label="previewDialogEmailLabel"
              :placeholder="previewDialogEmailPlaceholder"
            ></v-text-field>
          </v-card-text>

          <v-card-actions>
            <v-btn text color="primary" @click="closePreviewModal">{{ previewDialogCancelButton }}</v-btn>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="sendPreview" :disabled="!previewFromValid">{{ previewDialogConfirmButton }}</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <v-dialog
      width="600"
      v-model="deleteDialog"
    >
      <v-card
        class="px-4 py-4"
        v-if="deleteTemplate"
      >
        <v-card-title>{{ deleteDialogTitle }}</v-card-title>

        <v-card-actions>
          <v-btn text color="primary" @click="resetDeleteDialog">{{ deleteDialogCancelButton }}</v-btn>
          <v-spacer></v-spacer>
          <v-btn text color="red" @click="confirmDelete">{{ deleteDialogConfirmButton }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <component-guard
      :roles="['admin', 'manager']"
    >
      <side-panel v-model="sidePanel">
        <v-text-field
          hide-details="auto"
          outlined
          v-model="mailTemplateData.name"
          :label="sidebarNameLabel"
          :placeholder="sidebarNamePlaceholder"
        ></v-text-field>

        <v-text-field
          class="mt-4"
          hide-details="auto"
          outlined
          v-model="mailTemplateData.subject"
          :label="sidebarSubjectLabel"
          :placeholder="sidebarSubjectPlaceholder"
        ></v-text-field>

        <p class="mt-8 mb-8">{{ sidebarTemplatesText }}</p>

        <syntax-highlighted-input
          v-model="mailTemplateData.htmlText"
          grammar="django"
          language="django"
          :label="sidebarHtmlTextLabel"
          :placeholder="sidebarHtmlTextPlaceholder"
        ></syntax-highlighted-input>

        <syntax-highlighted-input
          v-model="mailTemplateData.plainText"
          grammar="django"
          language="django"
          class="mt-8"
          :label="sidebarPlainTextLabel"
          :placeholder="sidebarPlainTextPlaceholder"
        ></syntax-highlighted-input>

        <v-btn
          class="mt-12"
          color="primary"
          v-if="isEditAction"
          @click="editTemplate"
        >{{ sidebarUpdateButton }}</v-btn>

        <v-btn
          class="mt-12"
          color="green"
          v-else
          @click="createTemplate"
        >{{ sidebarCreateButton }}</v-btn>
      </side-panel>
    </component-guard>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import ComponentGuard from '../ComponentGuard'
import Copy from '../Copy'
import DataTable from '../DataTable'
import FormattedDate from '../FormattedDate'
import HelpDialog from '../HelpDialog'
import SidePanel from '../SidePanel'
import SyntaxHighlightedInput from '../form/SyntaxHighlightedInput'

import UserRoleAwareMixin from '@/mixins/userRoleAware.mixin'

import { shortenUuid } from '@/helpers/utility.helper'

import { email } from '@/helpers/validators.helpers'

export default {
  name: 'MailTemplates',
  mixins: [
    UserRoleAwareMixin
  ],
  components: {
    ComponentGuard,
    Copy,
    DataTable,
    FormattedDate,
    HelpDialog,
    SidePanel,
    SyntaxHighlightedInput
  },
  data: () => ({
    previewFromValid: false,
    primaryField: 'uuid',
    headers: [
      { value: 'name' },
      { value: 'createdOn' },
      { value: 'updatedOn' },
      { value: 'uuid' },
      { value: 'actions', sortable: false, align: 'end' }
    ],
    sidePanel: false,
    mailTemplateData: null,
    action: 'create',
    previewDialog: false,
    previewTemplate: null,
    previewEmail: '',
    deleteDialog: false,
    deleteTemplate: null
  }),
  async created () {
    this.resetMailTemplateData()

    await this.fetchMailTemplates()
  },
  methods: {
    ...mapActions('mailTemplates', ['fetchMailTemplates', 'createMailTemplate', 'updateMailTemplate', 'previewMailTemplate', 'deleteMailTemplate']),
    openSidePanel ({ uuid }) {
      if (uuid) {
        this.mailTemplateData = Object.assign({}, this.mailTemplate(uuid))
        this.action = 'edit'
      } else {
        this.action = 'create'
      }

      this.sidePanel = true
    },
    resetMailTemplateData () {
      this.mailTemplateData = { uuid: '', name: '', subject: '', htmlText: '', plainText: '' }
      this.action = 'create'
    },
    async createTemplate () {
      const success = await this.createMailTemplate({ ...this.mailTemplateData, callee: this._uid })
      if (success) {
        this.sidePanel = false
      }
    },
    async editTemplate () {
      const success = await this.updateMailTemplate({ ...this.mailTemplateData, callee: this._uid })
      if (success) {
        this.sidePanel = false
      }
    },
    openPreviewModal (item) {
      this.previewTemplate = item
      this.previewDialog = true
    },
    sendPreview () {
      this.previewMailTemplate({ uuid: this.previewTemplate.uuid, email: this.previewEmail })
        .then((success) => {
          if (success) {
            this.previewDialog = false
          }
        })
    },
    closePreviewModal () {
      this.previewDialog = false
      this.resetPreviewDialog()
    },
    resetPreviewDialog () {
      this.previewTemplate = null
      this.previewEmail = ''
    },
    handleDelete (item) {
      this.deleteTemplate = item
      this.deleteDialog = true
    },
    resetDeleteDialog () {
      this.deleteDialog = false
      this.deleteTemplate = null
    },
    async confirmDelete () {
      const success = await this.deleteMailTemplate({ uuid: this.deleteTemplate.uuid, callee: this._uid })
      if (success) {
        this.resetDeleteDialog()
      }
    },
    shortenUuid
  },
  computed: {
    ...mapGetters('mailTemplates', ['mailTemplate', 'mailTemplates', 'loading', 'total']),
    dataTable () {
      return {
        headers: this.headers,
        items: this.mailTemplates,
        loading: this.loading,
        showSelect: false,
        itemKey: this.primaryField,
        sortBy: 'updatedOn',
        sortDesc: true,
        disableFiltering: true,
        disablePagination: true,
        hideDefaultFooter: true,
        options: {},
        itemClass: ({ uuid }) => (this.mailTemplateData || {}).uuid === uuid ? 'grey lighten-4' : ''
      }
    },
    actions () {
      return [
        { icon: this.$icons.mdiEye, handler: this.openPreviewModal, disabled: this.sidePanel, tooltip: this.previewButtonTooltip },
        { icon: this.$icons.mdiPencil, handler: this.openSidePanel, disabled: this.sidePanel || this.isEditButtonDisabled, tooltip: this.editButtonTooltip },
        { icon: this.$icons.mdiDelete, handler: this.handleDelete, disabled: this.sidePanel || this.isDeleteButtonDisabled, tooltip: this.deleteButtonTooltip }
      ]
    },
    isEditAction () {
      return this.action === 'edit'
    },
    componentTranslationKey () {
      return `${this.pageTranslationKey}.components.mailTemplates`
    },
    tableTranslationKey () {
      return `${this.componentTranslationKey}.table`
    },
    headingPath () {
      return `${this.componentTranslationKey}.heading`
    },
    createButton () {
      return this.$t(`${this.componentTranslationKey}.buttons.create`)
    },
    dialogTranslationKey () {
      return `${this.componentTranslationKey}.table.dialogs`
    },
    sidebarTranslationKey () {
      return `${this.componentTranslationKey}.sidebar`
    },
    previewDialogTitle () {
      return this.$t(
        `${this.dialogTranslationKey}.preview.title`,
        { template: this.previewTemplate?.name ?? '' }
      )
    },
    previewDialogSubTitle () {
      return this.$t(`${this.dialogTranslationKey}.preview.subTitle`)
    },
    previewDialogEmailLabel () {
      return this.$t(`${this.dialogTranslationKey}.preview.fields.email.label`)
    },
    previewDialogEmailPlaceholder () {
      return this.$t(`${this.dialogTranslationKey}.preview.fields.email.placeholder`)
    },
    previewDialogCancelButton () {
      return this.$t(`${this.dialogTranslationKey}.preview.buttons.cancel`)
    },
    previewDialogConfirmButton () {
      return this.$t(`${this.dialogTranslationKey}.preview.buttons.confirm`)
    },
    deleteDialogTitle () {
      return this.$t(
        `${this.dialogTranslationKey}.delete.title`,
        { template: this.deleteTemplate?.name ?? '' }
      )
    },
    deleteDialogCancelButton () {
      return this.$t(`${this.dialogTranslationKey}.delete.buttons.cancel`)
    },
    deleteDialogConfirmButton () {
      return this.$t(`${this.dialogTranslationKey}.delete.buttons.confirm`)
    },
    sidebarNameLabel () {
      return this.$t(`${this.sidebarTranslationKey}.fields.name.label`)
    },
    sidebarNamePlaceholder () {
      return this.$t(`${this.sidebarTranslationKey}.fields.name.placeholder`)
    },
    sidebarSubjectLabel () {
      return this.$t(`${this.sidebarTranslationKey}.fields.subject.label`)
    },
    sidebarSubjectPlaceholder () {
      return this.$t(`${this.sidebarTranslationKey}.fields.subject.placeholder`)
    },
    sidebarHtmlTextLabel () {
      return this.$t(`${this.sidebarTranslationKey}.fields.htmlText.label`)
    },
    sidebarHtmlTextPlaceholder () {
      return this.$t(`${this.sidebarTranslationKey}.fields.htmlText.placeholder`)
    },
    sidebarPlainTextLabel () {
      return this.$t(`${this.sidebarTranslationKey}.fields.plainText.label`)
    },
    sidebarPlainTextPlaceholder () {
      return this.$t(`${this.sidebarTranslationKey}.fields.plainText.placeholder`)
    },
    sidebarCreateButton () {
      return this.$t(`${this.sidebarTranslationKey}.buttons.create`)
    },
    sidebarUpdateButton () {
      return this.$t(`${this.sidebarTranslationKey}.buttons.update`)
    },
    sidebarTemplatesText () {
      return this.$t(`${this.sidebarTranslationKey}.texts.templates`)
    },
    isEditButtonDisabled (item) {
      return !this.isAdmin && !this.isManager
    },
    isDeleteButtonDisabled (item) {
      return !this.isAdmin && !this.isManager
    },
    previewButtonTooltip () {
      return this.$t(`${this.tableTranslationKey}.actions.preview`)
    },
    editButtonTooltip () {
      return this.$t(`${this.tableTranslationKey}.actions.edit`)
    },
    deleteButtonTooltip () {
      return this.$t(`${this.tableTranslationKey}.actions.delete`)
    },
    previewDialogEmailRules () {
      return [
        v => !!v || this.$t('global.form.errorMessages.fields.generic.required'),
        email()
      ]
    }
  },
  inject: [
    'pageTranslationKey'
  ],
  watch: {
    sidePanel (value) {
      if (!value) {
        // prevent any flashing content while drawer is still closing
        setTimeout(this.resetMailTemplateData, 200)
      }
    },
    previewDialog (value) {
      if (!value) {
        // prevent any flashing content while dialog is still closing
        setTimeout(this.resetPreviewDialog, 200)
      }
    },
    deleteDialog (value) {
      if (!value) {
        this.resetDeleteDialog()
      }
    }
  }
}
</script>
