<template>
  <v-select
    :value="selection"
    :items="options"
    :label="label"
    :rules="rules"
    :disabled="disabled"
    item-text="label"
    @change="handleChange"
  ></v-select>
</template>

<script>
export default {
  name: 'SelectType',
  props: {
    value: {
      type: String,
      required: true
    },
    label: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    options: {
      type: Array,
      required: true
    },
    rules: {
      type: Array,
      default: () => []
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    selection: ''
  }),
  created () {
    this.selection = this.value
  },
  methods: {
    handleChange (data) {
      this.$emit('input', data)
    }
  },
  watch: {
    value (newValue) {
      this.selection = newValue
    }
  }
}
</script>
