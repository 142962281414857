<template>
  <v-container fluid class="mx-0 mb-0 mt-12 pa-0">
    <help-dialog
      :path="headingPath"
      tag="h2"
      css-classes="text-h6 text-md-h5 font-weight-bold mb-1 mb-sm-2"
    ></help-dialog>

    <v-row>
      <v-col cols="12" md="6">
        <h3 class="text-body-1 text-md-h6 font-weight-bold mb-2 d-inline-block">{{ deletionPeriodHeading }}</h3>
        <p class="text-body-2 text-sm-body-1 mb-6">{{ deletionPeriodSubHeading }}</p>

        <v-text-field
          v-model.number="deletionPeriod"
          :label="deletionPeriodLabel"
          :placeholder="deletionPeriodPlaceholder"
          :disabled="disabled"
          :suffix="unitDays"
          :min="deletionPeriodMin"
          :max="deletionPeriodMax"
          type="number"
          outlined
          @blur="handleDeletionPeriodFieldBlur"
          @change="handleChange"
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="6">
        <h3 class="text-body-1 text-md-h6 font-weight-bold mb-2 d-inline-block">{{ validityPeriodHeading }}</h3>
        <p class="text-body-2 text-sm-body-1 mb-6">{{ validityPeriodSubHeading }}</p>

        <v-text-field
          v-model.number="validityPeriod"
          :label="validityPeriodLabel"
          :placeholder="validityPeriodPlaceholder"
          :disabled="disabled"
          :suffix="unitDays"
          :min="validityPeriodMin"
          :max="validityPeriodMax"
          type="number"
          outlined
          @blur="handleValidityPeriodFieldBlur"
          @change="handleChange"
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="6">
        <h3 class="text-body-1 text-md-h6 font-weight-bold mb-2 d-inline-block">{{ retentionPeriodHeading }}</h3>
        <p class="text-body-2 text-sm-body-1 mb-6">{{ retentionPeriodSubHeading }}</p>

        <v-text-field
          v-model.number="retentionPeriod"
          :label="retentionPeriodLabel"
          :placeholder="retentionPeriodPlaceholder"
          :disabled="disabled"
          :suffix="unitDays"
          :min="retentionPeriodMin"
          :max="retentionPeriodMax"
          type="number"
          outlined
          @blur="handleRetentionPeriodFieldBlur"
          @change="handleChange"
        ></v-text-field>
      </v-col>
    </v-row>

    <v-btn color="primary" @click="savePeriods" :disabled="disabled">{{ saveButton }}</v-btn>
  </v-container>
</template>

<script>
import clamp from 'lodash/clamp'
import { mapActions, mapGetters } from 'vuex'

import HelpDialog from '../HelpDialog'

import UserRoleAwareMixin from '@/mixins/userRoleAware.mixin'

export default {
  name: 'PeriodSettings',
  mixins: [
    UserRoleAwareMixin
  ],
  components: {
    HelpDialog
  },
  props: {
    formName: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      deletionPeriod: 0,
      validityPeriod: 0,
      retentionPeriod: 0,
      deletionPeriodMin: 1,
      deletionPeriodMax: 4379,
      validityPeriodMin: 2,
      validityPeriodMax: 4379,
      retentionPeriodMin: 366,
      retentionPeriodMax: 4379,
      formTranslationKey: 'components.periodsForm'
    }
  },
  methods: {
    ...mapActions('settings', ['updateSettings']),
    setData () {
      this.deletionPeriod = this.periods.deletionPeriod
      this.validityPeriod = this.periods.validityPeriod
      this.retentionPeriod = this.periods.retentionPeriod
    },
    savePeriods () {
      this.updateSettings({
        data: {
          deletionTermDays: this.deletionPeriod,
          provisionTermDays: this.retentionPeriod,
          validityTermDays: this.validityPeriod
        },
        handlers: {
          '2xx': this.successHandler(this.formName),
          '4xx': this.errorHandler
        }
      })
    },
    handleDeletionPeriodFieldBlur () {
      this.deletionPeriod = clamp(this.deletionPeriod, this.deletionPeriodMin, this.deletionPeriodMax)
    },
    handleValidityPeriodFieldBlur () {
      this.validityPeriod = clamp(this.validityPeriod, this.validityPeriodMin, this.validityPeriodMax)
    },
    handleRetentionPeriodFieldBlur () {
      this.retentionPeriod = clamp(this.retentionPeriod, this.retentionPeriodMin, this.retentionPeriodMax)
    },
    handleChange () {
      this.$emit('changed', this.formName)
    }
  },
  created () {
    if (this.initialLoad) {
      this.setData()
    }
  },
  computed: {
    ...mapGetters('settings', ['settings', 'periods', 'initialLoad']),
    headingPath () {
      return `${this.formTranslationKey}.heading`
    },
    deletionPeriodHeading () {
      return this.$tp(`${this.formTranslationKey}.fields.deletionPeriod.heading`)
    },
    deletionPeriodSubHeading () {
      return this.$tp(`${this.formTranslationKey}.fields.deletionPeriod.subHeading`)
    },
    deletionPeriodLabel () {
      return this.$tp(`${this.formTranslationKey}.fields.deletionPeriod.label`)
    },
    deletionPeriodPlaceholder () {
      return this.$tp(`${this.formTranslationKey}.fields.deletionPeriod.placeholder`)
    },
    validityPeriodHeading () {
      return this.$tp(`${this.formTranslationKey}.fields.validityPeriod.heading`)
    },
    validityPeriodSubHeading () {
      return this.$tp(`${this.formTranslationKey}.fields.validityPeriod.subHeading`)
    },
    validityPeriodLabel () {
      return this.$tp(`${this.formTranslationKey}.fields.validityPeriod.label`)
    },
    validityPeriodPlaceholder () {
      return this.$tp(`${this.formTranslationKey}.fields.validityPeriod.placeholder`)
    },
    retentionPeriodHeading () {
      return this.$tp(`${this.formTranslationKey}.fields.retentionPeriod.heading`)
    },
    retentionPeriodSubHeading () {
      return this.$tp(`${this.formTranslationKey}.fields.retentionPeriod.subHeading`)
    },
    retentionPeriodLabel () {
      return this.$tp(`${this.formTranslationKey}.fields.retentionPeriod.label`)
    },
    retentionPeriodPlaceholder () {
      return this.$tp(`${this.formTranslationKey}.fields.retentionPeriod.placeholder`)
    },
    saveButton () {
      return this.$tp(`${this.formTranslationKey}.buttons.submit`)
    },
    disabled () {
      return !this.isAdmin && !this.isManager
    },
    unitDays () {
      return this.$t('global.units.days')
    }
  },
  inject: [
    'errorHandler',
    'successHandler',
    '$tp'
  ],
  watch: {
    settings () {
      this.setData()
    }
  }
}
</script>
