<template>
  <div>
    <div class="mb-8">
      <page-header :heading="pageHeading" :sub-heading="pageSubHeading"></page-header>
    </div>

    <v-container fluid class="pa-0">
      <settings-form
        @dirty="handleDirty"
      ></settings-form>
      <mail-templates></mail-templates>
    </v-container>
    <confirmation-dialog
      ref="leaveRouteConfirmationDialog"
      v-model="leaveRouteConfirmationDialog"
      :title="leaveRouteConfirmationDialogTitle"
      :text="leaveRouteConfirmationDialogText"
      :cancel="leaveRouteConfirmationDialogCancelText"
      :confirm="leaveRouteConfirmationDialogConfirmText"
      confirm-color="red"
      @cancel="cancelLeaveRoute"
      @confirm="confirmLeaveRoute"
    ></confirmation-dialog>
  </div>
</template>

<script>
import PageHeader from '@/components/PageHeader'
import MailTemplates from '@/components/settings/MailTemplates'
import SettingsForm from '@/components/settings/SettingsForm'

import ConfirmationDialog from '@/components/ConfirmationDialog'

import i18n from '@/mixins/i18n.mixin'
import Page from '@/mixins/page.mixin'
import LeaveRouteAware from '@/mixins/leaveRouteAware.mixin'

export default {
  name: 'Settings',
  components: {
    MailTemplates,
    PageHeader,
    SettingsForm,
    ConfirmationDialog
  },
  mixins: [
    i18n,
    Page,
    LeaveRouteAware
  ],
  data () {
    return {
      watchRouteChange: false
    }
  },
  methods: {
    handleDirty (event) {
      this.watchRouteChange = event
    }
  }
}
</script>
