<template>
  <component :is="rootElement" v-bind="rootElementProps">
    <slot v-bind="{ copy, text, display, displayText }">
      {{ displayText || '-' }}
    </slot>
    <v-tooltip right v-if="displayText">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          icon
          small
          @click="copy"
        >
          <v-icon small>{{ $icons.mdiContentCopy }}</v-icon>
        </v-btn>
      </template>
      <span v-text="copyButtonTooltip"></span>
    </v-tooltip>
  </component>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'Copy',
  props: {
    text: {
      type: String,
      required: true
    },
    display: {
      type: String,
      default: null
    },
    rootElement: {
      type: String,
      default: 'span'
    },
    rootElementProps: {
      type: Object,
      default: () => ({})
    },
    success: {
      type: String,
      default: null
    }
  },
  methods: {
    ...mapActions('messageQueue', ['queueSuccess']),
    copy () {
      navigator.clipboard.writeText(this.text)
      this.queueSuccess(this.successText)
    }
  },
  computed: {
    successText () {
      return this.success ?? this.defaultSuccessText
    },
    defaultSuccessText () {
      return this.$t('global.components.copy.success')
    },
    displayText () {
      return this.display ?? this.text
    },
    copyButtonTooltip () {
      return this.$t('global.components.dataTable.buttons.copyButton.tooltip')
    }
  }
}
</script>
