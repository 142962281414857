import { render, staticRenderFns } from "./EmbeddedObject.vue?vue&type=template&id=7c089aed&scoped=true&"
import script from "./EmbeddedObject.vue?vue&type=script&lang=js&"
export * from "./EmbeddedObject.vue?vue&type=script&lang=js&"
import style0 from "./EmbeddedObject.vue?vue&type=style&index=0&id=7c089aed&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7c089aed",
  null
  
)

export default component.exports