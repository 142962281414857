<template>
  <div
    class="v-input v-input--hide-details theme--light v-text-field v-text-field--is-booted v-text-field--enclosed v-text-field--outlined v-text-field--placeholder"
    :class="{ 'v-input--is-focused': editorHasFocus, 'primary--text': editorHasFocus }"
    @click="focusEditor"
  >
    <div class="v-input__control">
      <div class="v-input__slot">
        <fieldset aria-hidden="true">
          <legend :style="`width: ${this.legendWidth}px;`">
            <span class="notranslate">&#8203;</span>
          </legend>
        </fieldset>
        <div class="v-text-field__slot">
          <label
            ref="label"
            :for="inputId"
            class="v-label theme--light"
            :class="{ 'v-label--active': editorHasFocus || hasValue, 'primary--text': editorHasFocus }"
            style="left: 0; right: auto; position: absolute;"
          >
            {{ label }}
          </label>

          <prism-editor
            :readonly="disabled"
            :value="value"
            :highlight="highlighter"
            :class="{ 'editor--focused': editorHasFocus, 'editor--has-content': hasValue }"
            :line-numbers="lineNumbers"
            class="editor"
            @blur="editorHasFocus = false"
            @focus="editorHasFocus = true"
            @input="handleInput"
          ></prism-editor>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Prism from 'prismjs'
import { PrismEditor } from 'vue-prism-editor'
import { VInput } from 'vuetify/lib'

import 'vue-prism-editor/dist/prismeditor.min.css'

export default {
  name: 'SyntaxHighlightedInput',
  components: {
    PrismEditor
  },
  extends: VInput,
  props: {
    value: {
      type: String,
      required: true
    },
    grammar: {
      type: String,
      default: 'markup'
    },
    language: {
      type: String,
      default: 'html'
    },
    lineNumbers: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      editorHasFocus: false,
      labelWidth: 0
    }
  },
  mounted () {
    this.setLabelWidth()
  },
  methods: {
    highlighter (code) {
      if (!Prism.languages[this.grammar]) {
        return 'unsupported grammar'
      }

      return Prism.highlight(code, Prism.languages[this.grammar], this.language)
    },
    setLabelWidth () {
      this.labelWidth = this.$refs.label
        ? Math.min(this.$refs.label.scrollWidth * 0.75 + 6, this.$el.offsetWidth - 24)
        : 0
    },
    focusEditor () {
      this.$el.querySelector('textarea').focus()
    },
    handleInput (data) {
      this.$emit('input', data)
    }
  },
  computed: {
    highlightedValue () {
      return Prism.highlight(this.value, Prism.languages.javascript, 'javascript')
    },
    legendWidth () {
      return this.editorHasFocus || this.hasValue ? this.labelWidth : 0
    },
    hasValue () {
      return this.value.length > 0
    },
    inputId () {
      return `input-${this._uid}`
    }
  }
}
</script>

<style scoped>
.editor {
  --line-number-color: transparent;
  --line-number-z-index: -10;

  background: transparent;
  color: rgba(0, 0, 0, 0.87);

  padding-top: .75rem;
  padding-bottom: .75rem;

  font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
  font-size: 1rem;
  line-height: 1.5;
}

.editor--focused, .editor--has-content {
  --line-number-color: rgb(189, 189, 189);
  --line-number-z-index: 10;
}

.v-label {
  z-index: 10;
}

.v-text-field__slot {
  width: 100%;
}

/*noinspection CssInvalidPseudoSelector*/
:deep(.prism-editor__textarea:focus) {
  outline: none;
}

/*noinspection CssInvalidPseudoSelector*/
:deep(.prism-editor__line-number) {
  color: var(--line-number-color);
}

/*noinspection CssInvalidPseudoSelector*/
:deep(.prism-editor__line-numbers) {
  position: relative;
  z-index: var(--line-number-z-index);
}
</style>
