<template>
  <div>
    <help-dialog
      :path="headingPath"
      tag="h2"
      css-classes="text-h6 text-md-h5 font-weight-bold mb-1 mb-sm-2"
    ></help-dialog>

    <p class="text-body-2 text-sm-body-1 mb-6">{{ subHeading }}</p>

    <form-builder
      :config="settingsForm.config"
      :data="settingsForm.data"
      :errors="settingsForm.errors"
      :rules="settingsForm.rules"
      form-name="companyDataForm"
      @reset="handleReset"
      @change="handleChange"
      @submit="handleSubmit"
      :roles="['admin', 'manager']"
    >
      <template #fileType.companyLogoUuid.after="{ uuid }">
        <template v-if="uuid">
          <div>
            <span class="text-h6 mb-2 d-inline-block">{{ logoPreviewHeading }}</span>

            <embedded-object
              :source="getFileUrl(uuid)"
              :download="getFileUrl(uuid, true)"
              :fullscreen="false"
              fullscreen-height="auto"
              fullscreen-width="400"
              type="image"
            >
              {{ uuid }}
            </embedded-object>
          </div>
        </template>
      </template>

      <template #form.footer="{ submit, submitText, valid }">
        <v-btn color="primary" @click="submit" :disabled="!valid || submitButtonDisabled">
          {{ submitText }}
        </v-btn>
      </template>
    </form-builder>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import { getFileUrl } from '@/api/files.api'
import { max, required } from '@/helpers/validators.helpers'

import EmbeddedObject from '../EmbeddedObject'
import FormBuilder from '../form/FormBuilder'
import HelpDialog from '../HelpDialog'

import UserRoleAwareMixin from '@/mixins/userRoleAware.mixin'

export default {
  name: 'ContractSummarySettings',
  mixins: [
    UserRoleAwareMixin
  ],
  components: {
    EmbeddedObject,
    FormBuilder,
    HelpDialog
  },
  props: {
    formName: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      getFileUrl,
      settingsForm: {
        config: [
          [
            [
              {
                name: 'companyName',
                component: 'v-text-field'
              },
              {
                name: 'contactLine0',
                component: 'v-text-field'
              },
              {
                name: 'contactLine1',
                component: 'v-text-field'
              },
              {
                name: 'contactLine2',
                component: 'v-text-field'
              },
              {
                name: 'contactLine3',
                component: 'v-text-field'
              },
              {
                name: 'contactLine4',
                component: 'v-text-field'
              },
              {
                name: 'contactLine5',
                component: 'v-text-field'
              }
            ],
            [
              {
                name: 'companyLogoUuid',
                component: 'file-type',
                props: {
                  accept: ['image/png', 'image/jpeg']
                }
              }
            ]
          ]
        ],
        data: {
          companyName: '',
          contactLine0: '',
          contactLine1: '',
          contactLine2: '',
          contactLine3: '',
          contactLine4: '',
          contactLine5: '',
          companyLogoUuid: ''
        },
        errors: {
          companyName: '',
          contactLine0: '',
          contactLine1: '',
          contactLine2: '',
          contactLine3: '',
          contactLine4: '',
          contactLine5: '',
          companyLogoUuid: ''
        },
        rules: {
          companyName: [
            required,
            [max, { max: 60 }]
          ],
          companyLogoUuid: [
            required
          ],
          contactLine0: [
            [max, { max: 50 }]
          ],
          contactLine1: [
            [max, { max: 50 }]
          ],
          contactLine2: [
            [max, { max: 50 }]
          ],
          contactLine3: [
            [max, { max: 50 }]
          ],
          contactLine4: [
            [max, { max: 50 }]
          ],
          contactLine5: [
            [max, { max: 50 }]
          ]
        }
      }
    }
  },
  created () {
    if (this.initialLoad) {
      this.setData()
    }
  },
  methods: {
    ...mapActions('settings', ['updateSettings']),
    setData () {
      this.settingsForm.data = Object.assign({}, this.settings)
    },
    handleSubmit (data) {
      this.updateSettings({
        data,
        handlers: {
          '2xx': this.successHandler(this.formName),
          '4xx': this.errorHandler
        }
      })
    },
    handleChange () {
      this.$emit('changed', this.formName)
    },
    handleReset (event) {
      if (['createdUser', 'updatedUser'].indexOf(event) < 0) {
        this.$emit('reset', event)
      }
    }
  },
  computed: {
    ...mapGetters('settings', ['settings', 'initialLoad']),
    headingPath () {
      return 'components.companyDataForm.heading'
    },
    subHeading () {
      return this.$tp('components.companyDataForm.subHeading')
    },
    logoPreviewHeading () {
      return this.$tp('components.companyDataForm.fields.companyLogoUuid.preview.heading')
    },
    submitButtonDisabled () {
      return !this.isAdmin && !this.isManager
    }
  },
  inject: [
    'errorHandler',
    'successHandler',
    '$tp'
  ],
  watch: {
    settings () {
      this.setData()
    }
  }
}
</script>
